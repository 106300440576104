import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const HomeLayout = () => {

    const user = JSON?.parse(localStorage.getItem("token"));
    console.log('user', user)
    if (!user) {
        return <Navigate to="/login" replace />;
    }

    return <Outlet/>
}

export default HomeLayout